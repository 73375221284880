import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import './ContingentTranslate';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { loadDateReport, contingentModule, saveCause, exportData, saveTechnicalError } from './ContingentDucks';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import { ReportColumns } from '../../components/PageReport/ReportColumns'
import useTableFilter from '../../components/TableFilter/useTableFilter';
import ModalReportCard from '../../components/PageReport/ModalReportCard'
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import ColorInfo from '../../components/PageReport/ColorInfo'
import useUser from '../LoginPage/useUser';
import {ROLES} from '../../_helpers/Constants'
import FilterTreeSelect from '../../components/TableFilter/ColumnsFilters/FilterTreeSelect/FilterTreeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import { monthCurrent, createTableCell, getLastDayOfMonth } from '../../components/PageReport/reportUtils'
import Button from '../../_ui/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '../../components/TableFilter/ColumnsFilters/FilterAutocomplete';
import FilterSelect from '../../components/TableFilter/ColumnsFilters/FilterSelect';
import FilterDatePickerMonth from '../../components/TableFilter/ColumnsFilters/FilterDatePickerMonth';
import moment from 'moment';
import { monitoringVisitModule } from '../MonitoringVisitPage/MonitoringVisitDucks';

export default function ContingentDDOReport() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  const [days, setDays] = useState([]);
  const reportCardModal = useSimpleModal();
  const [isEdit, setIsEdit] = useState(true);
  const [isShowSum, setIsShowSum] = useState(false)
  const [counterVisitedDays, setCounterVisitedDays] = useState(0);
  const [counterDaysWithoutReason, setCounterDaysWithoutReason] = useState(0);
  const [counterDaysWithReason, setCounterDaysWithReason] = useState(0);
  const [copyFilter, setCopyFilter] = useState();
  const [currentOrg, setCurrentOrg] = useState(user?.organization_id);
  const [counterWithReasonReference, setCounterWithReasonReference] = useState(0);
  const [counterWithReasonLeaveApplication, setCounterWithReasonLeaveApplication] = useState(0);
  const [counterWithReasonParentStatement, setCounterWithReasonParentStatement] = useState(0);
  const [counterChildDays, setCounterChildDays] = useState(0);
  const [counterVisitSumma, setVisitSumma] = useState(0);
  const [counterTechnicalError, setCounterTechnicalError] = useState(0);
  const getFullYear = new Date().getFullYear();
  const [currentMonth, setCurrentMonth] = useState(monthCurrent(new Date().getMonth() + 1));
  const [isDisabledLocation, setIsDisabledLocation] = useState(false);
  const [userLocationCode, setUserLocationCode] = useState('');
  const [isEditHead, setIsEditHead] = useState(false);

  const { nextPage, changeFilter, filter } = useTableFilter(filter => {
    let activeMonth = moment(filter.dateMin).format('MM');
    const arrDays = createTableCell(getLastDayOfMonth(getFullYear, activeMonth), getFullYear, activeMonth);
    if (arrDays.length) {
      setDays(arrDays)
    }
    setCurrentMonth(activeMonth);
    filter['dateMax'] = `${getFullYear}-${activeMonth}-${getLastDayOfMonth(getFullYear, activeMonth)}T00:00:00`
    filter['pageSize'] = 26
    setCopyFilter({...filter})
    dispatch(loadDateReport({filter}));
  }, {
    pageNumber: 1,
    pageSize: 26,
    dateMin: `${getFullYear}-${currentMonth}-01T00:00:00`
  });

  const { currentOrgItem } = useSelector(
    state => state[monitoringVisitModule]
  );

  const { report, loadingTable, groupReport, organizations, cause, loadingExport, loadingSave } = useSelector(
    state => state[contingentModule]
  );

  const onExportHandle = () => {
    dispatch(exportData({filter: copyFilter, currentOrg}))
  };

  useEffect(() => {
    changeFilter({
      'serviceProviderLocation': currentOrgItem.legalLocality,
      'serviceProviderId': currentOrgItem.serviceProviderId
    })
  }, [currentOrgItem?.legalLocality])

  useEffect(() => {
    let visitedDays = 0;
    let withoutReason = 0;
    let withReason = 0;
    let withReasonReference = 0;
    let withReasonLeaveApplication = 0;
    let withReasonParentStatement = 0;
    let childDays = 0;
    let visitSumma = 0;
    let technicalError = 0
    report.content.forEach((item) => {
      setCounterVisitedDays(visitedDays += item.visitedDays);
      setCounterDaysWithoutReason(withoutReason += item.unvisitedDaysWithoutReason);
      setCounterDaysWithReason(withReason += item.unvisitedDaysWithReason);
      setCounterWithReasonReference(withReasonReference += item.unvisitedDaysWithReasonReference);
      setCounterWithReasonLeaveApplication(withReasonLeaveApplication += item.unvisitedDaysWithReasonLeaveApplication);
      setCounterWithReasonParentStatement(withReasonParentStatement += item.unvisitedDaysWithReasonParentStatement);
      setCounterTechnicalError(technicalError += item.unvisitedDaysWithReasonTechnicalError)
      setCounterChildDays(childDays += item.childDays);
      setVisitSumma(((visitSumma += parseFloat(item.visitSumma.toFixed(2))) * 100) / 100);
    })
  }, [report]);

  useEffect(() => {
    if (user) {
      user.roles.forEach((item) => {
        if (item === ROLES.ES_REGION_MIO_EMPLOYEES || item === ROLES.ES_REGION_EMPLOYEES || item === ROLES.ES_GODS) {
          setIsEdit(false);
          setCurrentOrg(false)
        }
        if (item === ROLES.ES_SERVICE_PROVIDER_HEADS || item === ROLES.ES_SERVICE_PROVIDER_EMPLOYEES) {
          setIsEditHead(true)
        }
        if ([ROLES.ES_REGION_MIO_EMPLOYEES].includes(item) && user?.legal_region) {
          changeFilter({
            'serviceProviderLocation': user?.legal_region,
          })
          setUserLocationCode(user?.legal_region);
          setIsDisabledLocation(true);
        } else if ([ROLES.ES_REGION_EMPLOYEES].includes(item) && user?.region_code) {
          changeFilter({
            'serviceProviderLocation': user?.region_code,
          })
          setUserLocationCode(user?.region_code);
          setIsDisabledLocation(true);
        } else if (item === ROLES.ES_GODS) {
          setIsShowSum(true);
        }
      })
    }
  }, [user])

  return (
    <>
      <Paper className="pb3 pt1 px3 mt2" style={{ minWidth: 2500 }}>
        <div className="flex justify-between items-center">
          <h3>{t('contingentPage_reportTitle')}</h3>
        </div>
        <div className="flex justify-between items-center mb2">
          <Box className="flex justify-between items-center">
            <Box className="flex justify-between items-center mr2">
              <Typography style={{fontSize: '12px', width: '100px'}}> { t('contingentPage_tableFor') } </Typography>
              <Box style={{width: '250px'}}>
                <FilterDatePickerMonth
                  name="dateMin"
                  lang={i18n.language}
                  currentMonth={currentMonth}
                />
              </Box>
            </Box>
            
            {
              !isEdit && !currentOrg &&
              <>
                <Box className="flex justify-between items-center mr2">
                  <Typography style={{fontSize: '12px', width: '200px'}}>{t('colleagues_location')}</Typography>
                  <FilterTreeSelect
                    lookupId="60"
                    name="serviceProviderLocation"
                    lang={i18n.language}
                    isEdit={isDisabledLocation}
                    userLocationCode={userLocationCode}
                    filter={item => item.ARM_gu === 'true'}
                  />
                </Box>
                <Box className="flex justify-between items-center mr2">
                  <Typography style={{fontSize: '12px', width: '140px'}}>{t('colleagues_organizations')}</Typography>
                  <Autocomplete
                    name="serviceProviderId"
                    options={organizations}
                  />
                </Box>
              </>
            }
            {
              (currentOrg || (!isEdit && copyFilter.serviceProviderId)) &&
              <FilterSelect
                name="groupId"
                options={groupReport}
                fullWidth={false}
                style={{ marginRight: 10, width: 150 }}
                placeholder={t('contingentPage_group')}
              />
            }
          </Box>
          <Box className="flex justify-between items-center">
            <Button
              onClick={onExportHandle}
              icon={<SaveIcon fontSize="small" className="mr1" />}
              text={t('colleagues_export_btn')}
              loading={loadingExport}
            />
            <Box className='mr2'></Box>
            <ColorInfo />
          </Box>
        </div>
        {  
          (!currentOrg && (!isEdit && !copyFilter.serviceProviderLocation)) &&
          <FormHelperText error style={{marginBottom: '10px', fontSize: '14px'}}>{t('contingentPage_isShowDataTable')}</FormHelperText>
        }
        <Box className='reportСard reportСardDDO'>
          <StaticTablePagination
            sortable={false}
            loading={loadingTable}
            columns={ReportColumns({
              t,
              lang: i18n.language,
              days,
              open: reportCardModal.open,
              cause,
              isShowSum,
              isEditHead: isEditHead,
              isEdit: isEdit
            })}
            currentlyResizing={true}
            data={report.content.length ? [...report.content, {
              name: '',
              visitedDays: counterVisitedDays,
              totalFinish: true,
              unvisitedDaysWithoutReason: counterDaysWithoutReason,
              unvisitedDaysWithReason: counterDaysWithReason,
              unvisitedDaysWithReasonReference: counterWithReasonReference,
              unvisitedDaysWithReasonLeaveApplication: counterWithReasonLeaveApplication,
              unvisitedDaysWithReasonParentStatement: counterWithReasonParentStatement,
              unvisitedDaysWithReasonTechnicalError: counterTechnicalError,
              childDays: counterChildDays,
              visitSumma: counterVisitSumma,
            }] : []}
            totalElements={report.totalElements ? report.totalElements + 1 : report.totalElements}
            onClickLoadMore={() => nextPage()}
          />
        </Box>
        { reportCardModal.isOpen &&
          <ModalReportCard
            {...reportCardModal}
            close={reportCardModal.close}
            saveCause={saveCause}
            cause={cause}
            loadingSave={loadingSave}
            isEdit={isEdit}
            saveTechnicalError={saveTechnicalError}
            filter={copyFilter}
          />
        }
      </Paper>
    </>
  );
}
