import axios from 'axios';
import { metaCaching } from '../utils/MetaData';
import appConfig from './config';
import paths from './paths';
import i18next from 'i18next';
import { queryParam } from '../utils/queryParam';
import { checkUnauthorized } from '../utils/checkUnauthorized';
import { dispatch } from '../_app/App';
import { setBadGateway } from '../components/BadGateway/BadGatewayDucks';
import { history } from './history';

const handleBaseUrlInterceptor = config => {
  if (config && config.url) {
    if (config.url.indexOf('api:services') !== -1) {
      const newConfig = {
        ...config,
        baseURL: appConfig.url.service,
        url: config.url.slice(12)
      };
      if (config.url.indexOf('/nedb-ddm/api/') !== -1) {
        newConfig.headers['Cache-Control'] = 'no-cache';
      }
      return newConfig;
    }
    if (config.url.indexOf('api:fileStore') !== -1) {
      return {
        ...config,
        baseURL: appConfig.url.fileStore,
        url: config.url.slice(13)
      };
    }
    if (config.url.indexOf('api:serviceQR') !== -1) {
      const newConfig = {
        ...config,
        baseURL: appConfig.url.serviceQR,
        url: config.url.slice(13)
      };
      if (config.url.indexOf('/nedb-ddm/api/') !== -1) {
        newConfig.headers['Cache-Control'] = 'no-cache';
      }
      return newConfig;
    }
  }
  return config;
};

const handleLanguageInterceptor = config => {
  let contentLang;
  switch (i18next.language) {
    case 'ru':
      contentLang = 'ru,ru-RU';
      break;
    case 'kk':
      contentLang = 'kk,kk-KZ';
      break;
    case 'en':
      contentLang = 'en,en-US';
      break;
    default:
      contentLang = 'en,en-US';
  }
  return {
    ...config,
    headers: {
      ...config.headers,
      'Content-Language': contentLang
    }
  };
};

const configureAuth = config => {
  if (!config.headers.Authorization) {
    const newConfig = {
      headers: {},
      ...config
    };
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      newConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return newConfig;
  }
  return config;
};

const unauthorizedResponse = async error => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiredAt');
    window.location.reload();
  } else if (error.response && error.response.status === 502) {
    dispatch(setBadGateway(true));
  }
  return Promise.reject(error);
};

axios.defaults.baseURL = appConfig.url.base;
axios.interceptors.request.use(handleBaseUrlInterceptor, e => Promise.reject(e));
axios.interceptors.request.use(handleLanguageInterceptor, e => Promise.reject(e));
axios.interceptors.request.use(configureAuth, e => Promise.reject(e));
axios.interceptors.response.use(r => r, unauthorizedResponse);

export const LoginApi = {
  login: data => axios.post('/login', data),
  logout: () => axios.get('/logout'),
  getStringToSign: () => axios.get('/login/signature'),
  loginWithSign: data => axios.post('/login/signature', data),
  getUserProfile: username =>
    axios.get(`api:serviceQR/nedb-entitlement/api/entlmnt/users/${username}`),
  refreshUser: token =>
    axios.post('/token/refresh', null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }),

  getUser: () => axios.get('/login')
};

export const ResetPassApi = {
  resetPassMail: username =>
    axios.post(
      `api:services/nedb-entitlement/api/entlmnt/users/reset-password/name/${username}?appName=ES_APP`
    ),
  resetPass: data =>
    axios.put(`api:services/nedb-entitlement/api/entlmnt/users/reset-password`, data)
};

export const StatementApi = {
  saveForm: changes => axios.put(`/api/public/passport/request_form`, changes),
  saveRequesterForm: changes => axios.put(`/api/public/passport/requester`, changes),
  loadRequestForm: id => axios.get(`/api/public/passport/request_form/${id}`),
  requester: id => axios.get(`/api/public/passport/requester/${id}`),
  loadMeta: metaKey => metaCaching(`/api/public/metadata/${metaKey}`),
  accept: id => axios.post(`/api/public/transitions/request_form/${id}/accept`),
  reject: (id, values) =>
    axios.post(`/api/public/transitions/request_form/${id}/reject`, values),
  sign: id => axios.get(`/api/public/signature/request_form/${id}/sign_string`),
  decline: (id, values) =>
    axios.post(`/api/public/transitions/request_form/${id}/decline`, values),
  complete: (id, values) =>
    axios.post(`/api/public/transitions/request_form/${id}/complete`, values),
  commitPositive: id =>
    axios.post(`/api/public/transitions/request_form/${id}/commit_positive`),
  commitNegative: (id, values) =>
    axios.post(`/api/public/transitions/request_form/${id}/commit_negative`, values),
  downloadCommitFile: (id, values) =>
    axios.post(`/api/public/info/request_form_view/${id}`, values),
  loadProvider: id => axios.get(`/api/public/passport/service_provider/${id}`),
  availableTransition: id => axios.get(`/api/public/transitions/request_form/${id}`),
  commitIntermediate: (id, sign) =>
    axios.post(`/api/public/transitions/request_form/${id}/commit_intermediate`, sign),
  changeServiceProvider: (id, providerId) =>
    axios.post(
      `/api/public/transitions/request_form/${id}/change_service_provider/${providerId}`
    ),
  downloadStatement: id =>
    axios.get(
      `/api/public/info/application_form_preview/${id}?force=true&action=download`,
      {
        responseType: 'arraybuffer'
      }
    ),
  previewStatement: id =>
    axios.get(`/api/public/info/application_form_preview/${id}?force=true&action=view`, {
      responseType: 'blob'
    })
};

export const JournalApi = {
  loadJournal: filter =>
    axios.get(`/api/public/info/paging/request_form?filter=${filter}`),
  assign: params => axios.post(`/api/public/transitions/request_form/assign`, params),
  archive: data => axios.post(`/api/public/transitions/request_form/${data.id}/archive`),
  exportData: (serviceType, filter = '') =>
    axios.get(
      `/api/public/export/excel/data/export_request_form${
        serviceType ? '_' + serviceType : ''
      }?${filter}`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/csv'
        }
      }
    )
};

export const ArchiveApi = {
  loadArchive: filter =>
    axios.get(`/api/public/info/paging/delegated_request_form?filter=${filter}`)
};

export const HandbookApi = {
  loadHandbook: () => axios.get(`api:services/nedb-ddm/api/v1/domainsets`)
};

export const ResponsibleApi = {
  loadResponsible: serviceProviderId =>
    axios.get(`/api/public/info/request_form_assignees/${serviceProviderId}`),
  loadUsers: usernames =>
    axios.get(
      `api:services/nedb-entitlement/api/entlmnt/users/short?usernames=${usernames}`
    )
};

export const DocumentApi = {
  download: (downloadLink, version) =>
    axios.get(`api:fileStore${downloadLink}?documentVersion=${version}&action=download`, {
      responseType: 'arraybuffer'
    }),
  view: (downloadLink, version) =>
    axios.get(`api:fileStore${downloadLink}?documentVersion=${version}&action=view`, {
      responseType: 'blob'
    }),
  openFile: (downloadLink) =>
    axios.get(`api:fileStore${downloadLink}`, {
      responseType: 'blob'
    })
};

export const ServiceProvidersApi = {
  loadMetaData: () => metaCaching('/api/public/metadata/SERVICE_PROVIDER'),
  load: id => axios.get(`/api/public/passport/service_provider/${id}`),
  createServiceProvider: values =>
    axios.post('/api/public/passport/service_provider', values),
  updateServiceProvider: values =>
    axios.put('/api/public/passport/service_provider', values),
  closeHeir: (id, values) =>
    axios.post(`/api/public/transitions/service_provider/${id}/close`, values),
  reopenHeir: (id, values) =>
    axios.post(`/api/public/transitions/service_provider/${id}/reopen`, values),
  loadTableData: filter =>
    axios.get(`/api/public/info/paging/service_provider?filter=${filter}`)
};

export const AdministrationApi = {
  loadUsers: (filter, page, attr) =>
    axios.get(
      `api:serviceQR/nedb-entitlement/api/entlmnt/users?filter=${filter}&page=${page}&attr=${attr}&item=ES_APP:login`
    ),
  changeUser: data =>
    axios.put(`api:serviceQR/nedb-entitlement/api/entlmnt/users/${data.username}`, data),
  createUser: data => axios.post(`api:serviceQR/nedb-entitlement/api/entlmnt/users`, data),
  activateUser: (username, data) =>
    axios.put(
      `api:serviceQR/nedb-entitlement/api/entlmnt/users/activation/${username}`,
      data
    ),
  deleteUser: username =>
    axios.delete(`api:serviceQR/nedb-entitlement/api/entlmnt/users/${username}`),
  changePassword: (username, data) =>
    axios.put(
      `api:serviceQR/nedb-entitlement/api/entlmnt/users/change-password/${username}`,
      data
    ),
  importUsers: (file, columns) =>
    axios.post(
      `api:services/nedb-entitlement/api/entlmnt/users/import?createNewIfUserWithDifferentIinExists=true&sendMail=true&appName=ES_APP${
        columns ? `&columnsToUpdate=${columns}` : ''
      }`,
      file
    )
};

export const MonitoringApi = {
  kindergartenRequestsQueue: () =>
    axios.get(`api/public/reports/kindergarten_requests_in_queue`, {
      responseType: 'arraybuffer',
      'Content-Language': 'ru,ru-RU'
    }),
  kindergartenRequestsQueueRegion: () =>
    axios.get(`api/public/reports/kindergarten_requests_in_queue/region `, {
      responseType: 'arraybuffer',
      'Content-Language': 'ru,ru-RU'
    }),
  kindergartenDirected: ({ fromDate, toDate }) =>
    axios.get(`/api/public/reports/directed_kindergarten_requests?filter={"submissionDateMin":"${fromDate}","submissionDateMax":"${toDate}"}`, {
      responseType: 'arraybuffer',
      'Content-Language': 'ru,ru-RU'
    }),
  kindergartenDirectedRegion: ({ fromDate, toDate }) =>
    axios.get(`/api/public/reports/directed_kindergarten_requests/region?filter={"submissionDateMin":"${fromDate}","submissionDateMax":"${toDate}"}`, {
      responseType: 'arraybuffer',
      'Content-Language': 'ru,ru-RU'
    }),
  kindergartenAccepted: ({ fromDate, toDate }) =>
    axios.get(`/api/public/reports/accepted_kindergarten_requests?filter={"submissionDateMin":"${fromDate}","submissionDateMax":"${toDate}"}`, {
      responseType: 'arraybuffer',
      'Content-Language': 'ru,ru-RU'
    }),
  kindergartenAcceptedRegion: ({ fromDate, toDate }) =>
    axios.get(`/api/public/reports/accepted_kindergarten_requests/region?filter={"submissionDateMin":"${fromDate}","submissionDateMax":"${toDate}"}`, {
      responseType: 'arraybuffer',
      'Content-Language': 'ru,ru-RU'
    }),
  kindergartenReportToMA: () => 
    axios.get(`/api/public/reports/queue-accepted`, {
      responseType: 'arraybuffer',
      'Content-Language': 'ru,ru-RU'
    }),
  loadData: (fromDate, toDate) =>
    axios.get(
      `/api/public/data/request_forms_group_by_type_area_status_by_datetime?request_date_begin=${fromDate}&request_date_end=${toDate}`
    )
};

export const TaskApi = {
  loadTasks: filter => axios.get(`/api/info/paging/tasks?filter=${filter}`),
  loadTask: id => axios.get(`/api/public/workflow/task/${id}`),
  loadTaskByProcessId: id => axios.get(`/api/public/workflow/task?filter={"processInstanceId": "${id}", "taskStatus":"IN_PROGRESS","showUnassigned":true}`),
  shortInfo: id => axios.get(`/api/public/info/request_form/${id}?id=${id}`),
  usersForSelect: id => axios.post(`/api/public/workflow/task/${id}/users`),
  usersSave: (ids, actionId, data) =>
    axios.put(
      `/api/public/workflow/task?ids=${JSON.stringify(ids)}&action=${actionId}`,
      data
    ),
  usersSubmit: (ids, actionId, data) =>
    axios.post(
      `/api/public/workflow/task?ids=${JSON.stringify(ids)}&action=${actionId}`,
      data
    ),
  saveMore: (ids, values) => axios.put(`/api/public/workflow/task?ids=${ids}`, values),
  completeMore: (ids, values) =>
    axios.post(`/api/public/workflow/task?ids=${ids}`, values),
  save: (id, actionId, values) =>
    axios.put(`/api/public/workflow/task/${id}?action=${actionId}`, values),
  complete: (id, actionId, values) =>
    axios.post(`/api/public/workflow/task/${id}?action=${actionId}`, values),
  signComplete: (id, values) => axios.post(`/api/public/workflow/task/${id}`, values),
  signReject: (id, values) => axios.post(`/api/public/workflow/task/${id}`, values),
  assignees: id => axios.post(`/api/public/workflow/task/${id}/assignees`),
  getSignString: id => axios.post(`/api/public/workflow/task/${id}/sign_string`),
  documentPreView: id =>
    axios.post(`/api/public/workflow/task/${id}/output_document_view`)
};

export const QueueApi = {
  loadTab1Data: filter =>
    axios.get(
      `/api/public/data/message_queue_group_by_app_by_queue_name_by_datetime?${queryParam(
        filter
      )}`
    ),
  loadTab2Data: requestId =>
    axios.get(
      `/api/public/data/message_queue_by_external_request_id?external_request_id=${requestId}`
    ),
  loadDataByName: filter =>
    axios.get(
      `/api/public/data/message_queue_by_app_by_status_by_queue_name_by_datetime?${queryParam(
        filter
      )}`
    ),
  cancelQueue: id => axios.get(`/api/public/data/message_queue_reset_by_id?id=${id}`)
};

export const BusinessProcessApi = {
  loadData: id => axios.get(`/api/public/workflow/request_form/${id}/instances`),
  startProcess: (processId, boId) =>
    axios.post(`/api/public/workflow/${processId}/request_form/${boId}`),
  stopProcess: processInstanceId =>
    axios.post(`/api/public/workflow/${processInstanceId}/stop`),
  stopTask: (processId, boId) =>
    axios.post(`/api/public/workflow/${processId}/request_form/${boId}`)
};

export const BulletinApi = {
  loadData: filter => axios.get(`/api/public/info/paging/bulletin?filter=${filter}`),
  getBulletin: id => axios.get(`/api/public/passport/bulletin/${id}`),
  requester: id => axios.get(`/api/public/passport/requester/${id}`),
  loadMeta: metaKey => metaCaching(`/api/public/metadata/${metaKey}`),
  save: changes => axios.put(`/api/public/passport/bulletin`, changes),
  create: changes => axios.post(`/api/public/passport/bulletin`, changes),
  delete: id => axios.post(`/api/public/transitions/bulletin/${id}/remove`),
  getSignString: params => axios.post(`/api/info/signature/sign_string/bulletin`, params),
  createRevokeSignString: params => axios.post(`/api/public/transitions/info/sign_string/revoke_bulletin/create`, params),
  createRevoke: (id, params) => axios.post(`/api/public/transitions/bulletin/${id}/revoke`, params),
  approveSignString: params => axios.post(`/api/public/transitions/info/sign_string/revoke_bulletin/agree`, params),
  approveRevoke: (id, params) => axios.post(`/api/public/transitions/bulletin/${id}/agree_revoke`, params),
  refuseSignString: (id, params) => axios.post(`/api/public/transitions/info/sign_string/revoke_bulletin/refuse`, params),
  refuseRevoke: (id, params) => axios.post(`/api/public/transitions/bulletin/${id}/refuse_revoke`, params)
};

export const DirectionApi = {
  loadData: filter => axios.get(`/api/public/info/paging/direction?filter=${filter}`),
  getDirection: id => axios.get(`/api/public/passport/direction/${id}`),
  getKinderGarden: id => axios.get(`/api/public/passport/kindergarten_queue/${id}`),
  loadMeta: metaKey => metaCaching(`/api/public/metadata/${metaKey}`),
  revoke: (id, params) => axios.post(`/api/public/transitions/direction/${id}/revoke_by_po`, params),
  approve: (id, params) => axios.post(`/api/public/transitions/direction/${id}/mark_arrived`, params),
  getSignString: params => axios.post(`api/public/transitions/info/sign_string/direction`, params)
};

export const StopListApi = {
  loadData: filter => axios.get(`/api/public/info/paging/kindergarten_queue?filter=${filter}`),
};

export const KezekArchiveApi = {
  loadData: filter => axios.get(`/api/public/info/paging/kindergarten_queue?filter=${filter}`),
};

export const KezekRequestApi = {
  loadData: filter => axios.get(`/api/public/info/paging/bulletin_on_revoke_check?filter=${filter}`),
};

export const ContingentApi = {
  loadData: (filter) => axios.get(`api/public/info/paging/contingent/child?filter=${filter}`),
  getContingentItem: (id) => axios.get(`/api/public/passport/contingent/${id}`),
  loadMeta: metaKey => metaCaching(`/api/public/metadata/${metaKey}`),
  loadRequestForm: id => axios.get(`/api/public/passport/request_form/${id}`),
  getSignString: params => axios.post(`api/public/transitions/info/sign_string/direction`, params),
  approve: (id, params) => axios.post(`/api/public/transitions/direction/${id}/mark_arrived`, params),
  revoke: (id, params) => axios.post(`/api/public/transitions/direction/${id}/revoke_by_po`, params),
  requestChildInfo: ({iin, queueType}) => axios.get(`/api/public/info/contingent_by_existing_direction?childIin=${iin}&queuedType=${queueType}`),
  getGroupsChild: (id) => axios.get(`/api/public/info/studyGroupsForChild/${id}`),
  retirement: (id, params) => axios.post(`/api/public/transitions/contingent/${id}/expel_from_po`, params),
  enroll: (id, params) => axios.post(`/api/public/transitions/contingent/${id}/enroll_to_po`, params),
  getDirection: (id) => axios.get(`/api/public/passport/direction/${id}`),
  savePassport: ({params}) => axios.put(`/api/public/passport/contingent`, params),
  addContingent: (params) => axios.post(`/api/public/passport/contingent`, params),
  getSignatureContingent: params => axios.post(`/api/public/transitions/info/sign_string/contingent`, params),
  getGroups: (params) => axios.get(`/api/public/info/study_groups_for_new_contingent`, { params: params }),
  exportApplicant: (data) => axios.get(`/api/public/reports/contingent/applicant?lang=${data.lang}`, {
    responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/csv'
      }
    }),
  exportEnrolled: (data) => axios.get(`/api/public/reports/contingent/enrolled?lang=${data.lang}`, {
    responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/csv'
      }
    }),
  exportExpelled: (data) => axios.get(`/api/public/reports/contingent/expelled?lang=${data.lang}`, {
    responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/csv'
      }
    }),
  loadDataReport: (filter) => axios.get(`/api/public/info/visit_log_ddo_child?filter=${filter}`),
  allGroups: (id) => axios.get(`/api/public/info/all_groups_by_sp_id/${id}`),
  saveItemReport: params => axios.post(`/api/v2/public/transitions/add_absence_reason_visit_log`, params),
  getOrganizations: location => axios.get(`/api/public/info/active_ddos/${location}`),
  exportDataContingent: (filter) => {
    return axios.get(
      `/api/public/reports/visit_log/do_child?filter=${filter}`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/csv'
        }
      }
    )},
  saveTechnicalError: params => axios.post(`/api/v1/public/transitions/add_technical_error_visit_log`, params)
};

export const GroupApi = {
  getGroups: (filter) => axios.get(`/api/public/info/studyGroup?filter=${filter}`),
  getItemGroup: ({id}) => axios.get(`/api/public/passport/study_group/${id}`),
  saveGroup: changes => axios.post(`/api/public/passport/study_group`, changes),
  updateGroup: changes => axios.put(`/api/public/passport/study_group`, changes),
  loadMeta: metaKey => axios.get(`/api/public/metadata/${metaKey}`),
  getGroupChild: ({filter, id}) => {
    return axios.get(`/api/public/info/contingents_in_group/${id}?filter=${filter}`)
  },
  getStudyGroup: (id) => axios.get(`/api/public/info/contingentsForStudyGroup/${id}`),
  addChildGroup: ({groupId, params}) => axios.put(`/api/public/transitions/add_child_to_group/${groupId}`, params),
  loadRequestForm: id => axios.get(`/api/public/passport/service_provider/${id}`),
  removeGroup: ({groupId}) => axios.put(`/api/public/transitions/delete_study_group/${groupId}`),
  getEducators: () => axios.get(`/api/public/info/preschool_teachers_for_group`),
}
export const ClassApi = {
  getClass: (filter) => axios.get(`/api/public/info/study_group_school?filter=${filter}`),
  getItemClass: ({id}) => axios.get(`/api/public/passport/study_group/${id}`),
  saveClass: changes => axios.post(`/api/public/passport/study_group`, changes),
  updateClass: changes => axios.put(`/api/public/passport/study_group`, changes),
  loadMeta: metaKey => axios.get(`/api/public/metadata/${metaKey}`),
  getClassChild: ({filter, id}) => {
    return axios.get(`/api/public/info/school_students_in_group/${id}?filter=${filter}`)
  },
  loadRequestForm: id => axios.get(`/api/public/passport/service_provider/${id}`),
  removeClass: ({id}) => axios.put(`/api/public/transitions/delete_study_group/${id}`),
  getEducators: () => axios.get(`/api/public/info/school_teachers_for_group`),
}
export const ColleaguesApi = {
  loadMeta: metaKey => axios.get(`/api/public/metadata/${metaKey}`),
  getEmployees: (filter) => axios.get(`/api/public/info/paging/staff?filter=${filter}`),
  getSignEmployeesString: params => axios.post(`api/public/transitions/info/sign_string/direction`, params),
  setEmployee: data => axios.post(`/api/public/passport/staff`, data),
  updateEmployee: data => axios.put(`/api/public/passport/staff`, data),
  getGroup: filter => axios.get(`/api/public/info/studyGroup?filter=${filter}`),
  getItemEmployees: (id) => axios.get(`/api/public/passport/staff/${id}`),
  getSignString: params => axios.post(`/api/public/transitions/info/sign_string/dismiss_staff`, params),
  dismissStaff: params => axios.post(`/api/public/transitions/dismiss_staff`, params),
  loadDataReport: (filter) => axios.get(`/api/public/info/visit_log_staff?filter=${filter}`),
  saveItemReport: params => axios.post(`/api/v2/public/transitions/add_absence_reason_visit_log_staff`, params),
  getOrganizations: location => axios.get(`/api/public/info/active_organizations/${location}`),
  exportDataColleagues: (filter) => { 
    return axios.get(
      `/api/public/reports/visit_log/staff?filter=${filter}`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/csv'
        }
      }
    )}
}

export const ContingentCommonApi = {
  loadMeta: metaKey => axios.get(`/api/public/metadata/${metaKey}`),
  loadChildSchool: (filter) => axios.get(`/api/public/info/paging/contingent/school_student?filter=${filter}`),
  loadChildSection: (filter) => axios.get(`/api/public/info/paging/contingent/section_visitor?filter=${filter}`),
  setContingent: data => axios.post(`/api/public/passport/contingent`, data),
  getContingentItem: (id) => axios.get(`/api/public/passport/contingent/${id}`),
  changeContingent: data => axios.put(`/api/public/passport/contingent`, data),
  disposalSchool: data => axios.post('/api/public/transitions/contingent/left_school', data),
  disposalSection: data => axios.post('/api/public/transitions/contingent/left_section', data),
  getSignString: params => axios.post(`api/public/transitions/info/sign_string/contingent`, params),
  loadDataReport: (filter) => axios.get(`/api/public/info/visit_log_staff?filter=${filter}`),
  loadSectionReport: (filter) => axios.get(`/api/public/info/visit_log_section_visitor?filter=${filter}`),
  loadSchoolReport: (filter) => axios.get(`/api/public/info/visit_log_school_student?filter=${filter}`),
  loadSchoolFoodReport: (filter) => axios.get(`/api/public/info/school_students_free_meal_info?filter=${filter}`),
  saveItemReport: params => axios.post(`/api/public/transitions/add_absence_reason_visit_log`, params),
  getSectionsOrganizations: location => axios.get(`/api/public/info/active_sections/${location}`),
  getSchoolsOrganizations: location => axios.get(`/api/public/info/active_schools/${location}`),
  getClasses: () => axios.get(`/api/public/info/study_groups_for_school_student`),
  getClassesOrg: (id) => axios.get(`/api/public/info/all_groups_school_by_sp_id/${id}`),
  exportDataSchool: (filter) => { 
    return axios.get(
      `/api/public/reports/visit_log/school_student?filter=${filter}`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/csv'
        }
      }
    )},
  exportDataSection: (filter) => { 
      return axios.get(
        `/api/public/reports/visit_log/section_visitor?filter=${filter}`,
        {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'text/csv'
          }
        }
      )},
  exportDataFree: (filter) => {
    return axios.get(
      `/api/public/reports/school_students_free_meal_info?filter=${filter}`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/csv'
        }
      }
    )},
}

export const MonitoringVisitApi = {
  loadData: (filter) => axios.get(`/api/public/info/po_visit_monitoring?filter=${filter}`),
  loadDataMonitoringPercentage: (filter) => axios.get(`/api/public/info/po_visit_monitoring_percentage_by_day?filter=${filter}`),
  exportData:(filter) => {
    return axios.get(
      `/api/public/reports/po_visit_log_monitoring?filter=${filter}`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/csv'
        }
      }
    )},
}
