import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import i18next from 'i18next';
import { ContingentApi, MonitoringVisitApi } from '../../_helpers/service';
import Dict from '../../utils/Dict';

/**
 * Constants
 */
export const monitoringVisitModule = 'monitoringVisit';
const LOADING = `${monitoringVisitModule}/LOADING`;
const SET_DATA = `${monitoringVisitModule}/SET_DATA`;
const LOADING_EXPORT_DATA = `${monitoringVisitModule}/LOADING_EXPORT_DATA`;
const SET_GENERAL  = `${monitoringVisitModule}/SET_GENERAL`;
const SET_REPORT = `${monitoringVisitModule}/SET_REPORT`;
const SET_CAUSE = `${monitoringVisitModule}/SET_CAUSE`;
const SET_CURRENT_ORG = `${monitoringVisitModule}/SET_CURRENT_ORG`;

/**
 * Reducer
 */
const initialState = {
  loading: true,
  loadingExport: false,
  data: {
    content: [],
    totalElements: 0
  },
  report: {
    content: [],
    totalElements: 0
  },
  cause: [],
  currentOrgItem: {},
  general: []
};

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [SET_DATA]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.data.content = [...state.data.content, ...action.payload.content];
      state.data.totalElements = action.payload.totalElements;
    } else {
      state.data = action.payload;
    }
  },
  [SET_GENERAL]: (state, action) => {
    state.general = action.payload;
  },
  [SET_REPORT]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.report.content = [...state.report.content, ...action.payload.content];
      state.report.totalElements = action.payload.totalElements;
    } else {
      state.report = action.payload;
    }
  },
  [SET_CAUSE]: (state, action) => {
    state.cause = action.payload;
  },
  [SET_CURRENT_ORG]: (state, action) => {
    state.currentOrgItem = action.payload;
  },
  [LOADING_EXPORT_DATA]: (state, action) => {
    state.loadingExport = action.payload;
  },
});

/**
 * Actions
 */
export const loadData = filter => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    const {data} = await MonitoringVisitApi.loadData(JSON.stringify(filter));
    if (data.status === 'SUCCESS') {
      const dictKato = await Dict.itemsObject('60');
      for (let row of data.result.content) {
        row.location = dictKato[row.legalLocality] || {};
      }
      dispatch({ type: SET_DATA, payload: data.result, filter })
    }
  } catch (error) {
    handleError(error, i18next.t('monitoringVisit_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const loadDataTotal = filter => async dispatch => {
  try {
    const { data } = await MonitoringVisitApi.loadDataMonitoringPercentage(JSON.stringify(filter));
    if (data.status === "SUCCESS") {
      dispatch({ type: SET_GENERAL, payload: {
        isPercentage: true,
        serviceProviderKkName: `Барлығы жасалған белгілер:`,
        serviceProviderRuName: `Всего отметок сделали:`,
        visitsInfo: data.result
      }})
    }
  } catch (error) {
    handleError(error, i18next.t('monitoringVisit_errorLoadTable'));
  }
};

export const exportData = (filter) => async dispatch => {
  try {
    dispatch({ type: LOADING_EXPORT_DATA, payload: true });
    delete filter.pageNumber;
    delete filter.pageSize;
    const response = await MonitoringVisitApi.exportData(JSON.stringify(filter));
    download(response.data, `${i18next.t('monitoringVisit_title')}.xlsx`);
  } catch (error) {
    handleError(error, i18next.t('monitoringVisit_reportExportError'));
  } finally {
    dispatch({ type: LOADING_EXPORT_DATA, payload: false });
  }
};

export const loadDateReport = (filter) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    if (filter.serviceProviderId) {
      const {data} = await ContingentApi.loadDataReport(JSON.stringify(filter));
      if (data.status === 'SUCCESS') {
        data.result.content.forEach((item) => {
          let daysWithoutReason = 0;
          if (item.unvisitedDaysWithoutReason <= 3) {
            daysWithoutReason = item.unvisitedDaysWithoutReason;
          }
          item['childDays'] = item.visitedDays + item.unvisitedDaysWithReason + daysWithoutReason;
          item['visitSumma'] = ((item.childDays / item.numberOfWorkingDays) * item.monthCoefficient);
        })
        let reasonChildren = await Dict.itemsObject('absence_reason_children');
        let reasonList = []
        for (let variable in reasonChildren) {
          reasonList.push(reasonChildren[variable])
        }
        dispatch({ type: SET_CAUSE, payload: reasonList })
        dispatch({ type: SET_REPORT, payload: data.result, filter })
      }
    } else {
      dispatch({
        type: SET_REPORT,
        payload: {
          content: [],
          totalElements: 0},
        filter
      })
    }
  } catch (error) {
    handleError(error, i18next.t('contingentPage_reportErrorLoadTable'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const currentOrg = (org) => ({ type: SET_CURRENT_ORG, payload: org });

const download = (data, fileName) => {
  let element = document.createElement('a');
  document.body.appendChild(element);
  element.style.display = 'none';
  let blob = new Blob([data]);
  let url = window.URL.createObjectURL(blob);
  element.href = url;
  element.download = fileName;
  element.click();
  window.URL.revokeObjectURL(url);
};
