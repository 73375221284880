import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    pageReport_fullName: 'ФИО',
    pageReport_reference: 'Справка',
    pageReport_visitedDays: 'Пришел',
    pageReport_withoutReason: 'Причина',
    pageReport_withReason: 'Без причины',
    pageReport_missedDays: 'Пропущенные дни',
    pageReport_total: 'ВСЕГО',
    pageReport_arrivalDate: 'Дата прихода',
    pageReport_scanTime: 'Время сканирования QR кода:',
    pageReport_reportModalTitle: 'Основание отсутствия',
    pageReport_reportCause: 'Причина',
    pageReport_requiredField: 'Обязательное для заполнения',
    pageReport_reportCauseCancel: 'Отменить причину',
    pageReport_saveBtn: 'Сохранить',
    pageReport_reportConfirmationDocument: 'Подтверждающий документ',
    pageReport_downloadReference: 'Скачать справку',
    pageReport_formatIsNotSupported: 'Данный формат файла не поддерживается. Пожалуйста, загрузите файл в формате PDF, JPEG, JPG, PNG.',
    pageReport_applicationLeave: 'Заявление в отпуск',
    pageReport_statementParents: 'Заявление от родителей',
    pageReport_childrenDays: 'Детодни',
    pageReport_sum: 'Сумма',
    pageReport_requiredStartEndDate: 'Укажите период',
    pageReport_technicalError: 'Техническая ошибка'
  },
  kk: {
    pageReport_fullName: 'ТАӘ',
    pageReport_reference: 'Анықтама',
    pageReport_visitedDays: 'Келген күн',
    pageReport_withoutReason: 'Себепті',
    pageReport_withReason: 'Себепсіз',
    pageReport_missedDays: 'Қалған күн',
    pageReport_total: 'БАРЛЫҒЫ',
    pageReport_arrivalDate: 'Келу күні',
    pageReport_scanTime: 'QR кодын сканерлеу уақыты:',
    pageReport_reportModalTitle: 'Болмау негізі',
    pageReport_reportCause: 'Себебі',
    pageReport_requiredField: 'Толтыру үшін міндетті',
    pageReport_saveBtn: 'Сақтау',
    pageReport_reportCauseCancel: 'Себебін жою',
    pageReport_reportConfirmationDocument: 'Растайтын құжат',
    pageReport_downloadReference: 'Анықтаманы жүктеу',
    pageReport_formatIsNotSupported: 'Файл форматы қате. Тек PDF, JPEG, JPG, PNG файлдарын жүктеңіз.',
    pageReport_applicationLeave: 'Демалысқа өтініш',
    pageReport_statementParents: 'Ата-анасының өтініші',
    pageReport_childrenDays: 'Детодни',
    pageReport_sum: 'Сомасы',
    pageReport_requiredStartEndDate: 'Кезеңді көрсетіңіз',
    pageReport_technicalError: 'Техникалық қате'

  }
});
